import React from 'react';
import { useRouter } from 'next/router';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
  Link,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useTranslation } from 'next-i18next';

import StemiIcon from './StemiIcon';
import LocaleSelect from './LocaleSelect';

const Links = [
  { label: 'Editor', url: '/editor' },
  { label: 'All projects', url: '/showcase/2024' },
  { label: '2023', url: '/showcase/2023' },
  { label: '2022', url: '/showcase/2022' },
  { label: '2021', url: '/showcase/2021' },
];

interface NavLinkProps {
  label: string;
  url: string;
  isSelected?: boolean;
}

const NavLink: React.FC<NavLinkProps> = (props) => {
  const { label, url, isSelected } = props;

  return (
    <Link
      px={2}
      py={1}
      _hover={{
        textDecoration: 'none',
        bg: useColorModeValue('gray.200', 'gray.700'),
      }}
      color="#102e8e"
      borderColor="#102e8e"
      href={url}
      borderBottomWidth={isSelected ? '5px' : '0px'}
    >
      {label}
    </Link>
  );
};

const Navigation = () => {
  const router = useRouter();
  const { t } = useTranslation('common');

  return (
    <Box
      backgroundColor="white"
      px={8}
      pt={4}
      pb={4}
      height="88px"
      alignItems="center"
      justifyContent="center"
      zIndex={2}
    >
      <Flex
        h="100%"
        w="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex
          flexDirection="row"
          w="100%"
          h="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Link href={`/${router.locale}/showcase`}>
            <StemiIcon />
          </Link>

          <HStack
            as="nav"
            spacing={8}
            display={{ base: 'none', md: 'flex' }}
            mr="48px"
          >
            {Links.map((link) => (
              <NavLink
                {...link}
                key={link.url}
                isSelected={router.asPath.includes(link.url)}
                label={t(link.label)}
                url={`/${router.locale}${link.url}`}
              />
            ))}
          </HStack>
        </Flex>

        <LocaleSelect />
        <Box display={{ md: 'none' }}>
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<HamburgerIcon />}
              aria-label={t('ariaLabel.menu')}
            />
            <MenuList>
              {Links.map((link) => (
                <Link
                  as="div"
                  key={link.url}
                  href={`/${router.locale}${link.url}`}
                >
                  <MenuItem>{link.label}</MenuItem>
                </Link>
              ))}
            </MenuList>
          </Menu>
        </Box>
      </Flex>
    </Box>
  );
};

export default Navigation;
