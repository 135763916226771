import React, { Fragment, useEffect, useState } from 'react';
import { HStack, IconButton, SimpleGrid } from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import debounce from 'debounce';

import JsonTableHead from './JsonTableHead';
import JsonTableCell from './JsonTableCell';
import { useTranslation } from 'next-i18next';

interface JsonTableContainerProps {
  initialData: (string | number)[][];
  initialColumnKeys: string[];
  onChange: (value: Record<string, any>[]) => void;
}

const JsonTableContainer = (props: JsonTableContainerProps) => {
  const { initialData, initialColumnKeys, onChange } = props;
  const [data, setData] = useState(initialData);
  const [columnKeys, setColumnKeys] = useState(initialColumnKeys);
  const { t } = useTranslation('common');
  useEffect(() => {
    setData(initialData);
    setColumnKeys(initialColumnKeys);
  }, [initialData, initialColumnKeys]);

  const generateData = () => {
    return data.map((rowData) => {
      const rowObj = {};
      columnKeys.forEach((columnKey, cellIndex) => {
        rowObj[columnKey] = rowData[cellIndex];
      });
      return rowObj;
    });
  };

  useEffect(() => {
    onChange(generateData());
  }, [data, columnKeys]);

  const firstColumnValues = data.map((d) => d[0]);

  return (
    <SimpleGrid columns={columnKeys.length + 1} spacingY="4px">
      {columnKeys.map((key, index) => (
        <JsonTableHead
          key={index as any}
          data={key}
          isKey={index === 0}
          disabled={index === 0}
          onCellValueChange={(value) =>
            setColumnKeys((oldColumnKeys) => {
              oldColumnKeys[index] = value;
              return [...oldColumnKeys];
            })
          }
        />
      ))}
      <HStack>
        <IconButton
          onClick={() =>
            setColumnKeys((oldColumnKeys) => [
              ...oldColumnKeys,
              oldColumnKeys.length === 0 ? 'id' : `col${oldColumnKeys.length}`,
            ])
          }
          icon={<AddIcon />}
          aria-label={t('ariaLabel.addColumn')}
          size="md"
          bg="itemBackground"
          color="primary"
          width="40px"
        />
        <IconButton
          onClick={() =>
            setColumnKeys((oldColumnKeys) => {
              oldColumnKeys.splice(oldColumnKeys.length - 1, 1);
              return [...oldColumnKeys];
            })
          }
          icon={<MinusIcon />}
          aria-label={t('ariaLabel.removeColumn')}
          size="md"
          bg="itemBackground"
          color="primary"
          width="40px"
          isDisabled={columnKeys.length <= 1}
        />
      </HStack>
      {initialData.map((rowData, rowIndex) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={`${rowIndex}-${rowData[0]}`}>
            {columnKeys.map((_v, cellIndex) => {
              const onCellValueChange = (value) =>
                setData((oldData) => {
                  oldData[rowIndex][cellIndex] = value;
                  return [...oldData];
                });
              const debouncedOnCellValueChange = debounce(
                onCellValueChange,
                400,
              );
              return (
                <JsonTableCell
                  key={`${rowIndex}-${cellIndex}` as any}
                  data={rowData[cellIndex] || ''}
                  isKey={cellIndex === 0}
                  isInvalid={
                    cellIndex === 0 &&
                    firstColumnValues.filter(
                      (firstColumnValue) =>
                        firstColumnValue === rowData[cellIndex],
                    ).length > 1
                  }
                  onCellValueChange={debouncedOnCellValueChange}
                />
              );
            })}
            <div />
          </Fragment>
        );
      })}
      <HStack>
        <IconButton
          onClick={() => {
            const newData = [
              ...data,
              [...columnKeys.map((k) => k + data.length)],
            ];
            setData(newData);
          }}
          icon={<AddIcon />}
          aria-label={t('ariaLabel.addRow')}
          size="md"
          bg="itemBackground"
          color="primary"
          width="40px"
        />

        <IconButton
          onClick={() =>
            setData((oldData) => {
              oldData.splice(oldData.length - 1, 1);
              return [...oldData];
            })
          }
          icon={<MinusIcon />}
          isDisabled={data.length <= 1}
          aria-label={t('ariaLabel.close')}
          size="md"
          bg="itemBackground"
          color="primary"
          width="40px"
        />
      </HStack>
    </SimpleGrid>
  );
};

export default JsonTableContainer;
