import { Box, Button, Spinner } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const LocaleSelect = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { locale, locales, asPath, pathname, query, push } = useRouter();
  const shortLocales = locales.map((l) => l.slice(0, 2));
  const shortLocale = locale.slice(0, 2);
  const [selectedLocale, setSelectedLocale] = useState(locale);
  const [isMenuOpened, setMenuOpened] = useState(false);

  const languages: any = [
    { code: 'en', name: 'English', flag: 'https://flagcdn.com/w320/us.png' },
    {
      code: 'pt',
      name: 'Português (Brasil)',
      flag: 'https://flagcdn.com/w320/br.png',
    },
    { code: 'hr', name: 'Hrvatski', flag: 'https://flagcdn.com/w320/hr.png' },
  ];

  useEffect(() => {
    setIsLoading(false);
  }, [locale]);
    
  const changeLocale = (code: string) => {
    setIsLoading(true);
    const nextLocale = locales[code];
    setSelectedLocale(nextLocale);
    push({ pathname, query }, asPath, { locale: code });
  };


  return (
    <div style={{ position: 'relative', paddingLeft: "20px", paddingRight: "10px", display: 'inline-block', zIndex: 1000 }}>
      <button
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '10px',
          fontSize: '16px',
          borderRadius: '5px',
          cursor: 'pointer',
          backgroundColor: 'var(--chakra-colors-gray-800)',
          border: '1px solid #ccc',
          // minWidth: '20px',
          paddingRight: '10px',
          width: '5vw',
          height: "32px",
          zIndex: 10000
        }}
        onClick={() => {
          setMenuOpened((isOpened) => !isOpened);
        }}
      >
        <img
          style={{ width: 20, height: 14, marginRight: 0 }}
          src={
            languages.find((lang: any) => lang.code === selectedLocale)?.flag
          }
        />
        <span style={{ marginLeft: 10 }}>{isMenuOpened ? '▲' : '▼'}</span>
      </button>
      <ul
        style={{
          display: isMenuOpened ? 'block' : 'none',
          listStyle: 'none',
          padding: 0,
          margin: 0,
          position: 'absolute',
          marginTop: '10px',
          top: '100%',
          right: "0px",
          backgroundColor: 'var(--chakra-colors-gray-800)',
          border: '1px solid #ccc',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          zIndex: 20000,
          overflowY: 'auto',
          maxHeight: '200px',
          width: '150px',
          }}
        id="language-options"
      >
        {languages.map((lang: any) => (
          <li
            key={lang.code}
            onClick={() => {
              console.log(lang);
              changeLocale(lang.code);
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '10px',
              cursor: 'pointer',
              transition: 'background-color 0.2s',
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor = '#3055f6')
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.backgroundColor = 'transparent')
            }
          >
            <img
              src={lang.flag}
              alt={lang.name}
              style={{ width: 20, height: 14, marginRight: 10 }}
            />
            {lang.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LocaleSelect;
