import React from 'react';
import { Badge, Box, Flex, HStack, IconButton, Text } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';

import { PolicyData } from '@projectTypes/Policy';
import ComponentValue from '../ParamValue';
import { useTranslation } from 'next-i18next';

interface PipelineComponentProps {
  component: PolicyData;
  removeComponent: () => void;
  setValue: (paramName: string, value: any) => void;
}

const PipelineComponent = (props: PipelineComponentProps) => {
  const { component, removeComponent, setValue } = props;
  const { t } = useTranslation('common');
  return (
    <Box
      bg="primary"
      p="6"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
    >
      <Flex flexDirection="row" justifyContent="space-between">
        <HStack spacing={4}>
          <Badge borderRadius="full" px="2" colorScheme="green">
            {component.name}
          </Badge>
        </HStack>
        <IconButton
          onClick={removeComponent}
          aria-label={t('ariaLabel.deleteComponent')}
          icon={<DeleteIcon />}
          bg="secondary"
          color="white"
        />
      </Flex>

      <Text fontSize="3xl" color="white">
        {component.name}
      </Text>
      {component.params?.map((param, index) => (
        <ComponentValue
          key={index as any}
          param={param}
          setValue={(value: any) => setValue(param.name, value)}
        />
      ))}
    </Box>
  );
};

export default PipelineComponent;
